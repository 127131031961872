import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import Loader from '../common/loader/loader';
import Table from '../common/table/table';
import { connect } from 'react-redux';
import { formatDateAndTime, TimeTablePeriods } from '../../resources/constants';

const Assessment = (props) => {
  const token  = props.loginData[0].token;
    const [loading, setIsLoading] = useState(false)
    const [assessmentName, setAssessmentName] = useState([]);
    const studentID = props.loginData[0].StudentID;

    const [ModuleName, setModuleName] = useState([]);
    const [assessment, setAssessment] = useState([]);

    const getData = async () => {
        axios.post(`${serverLink}students/thesis/assessment`, { studentID: studentID }, token).then((result) => {
            if (result.data.length > 0) {
                setModuleName(result.data);
            }
        })
        axios.post(`${serverLink}students/thesis/fetch/ca`, { studentID: studentID }, token).then((result) => {
            if (result.data.length > 0) {
                setAssessment(result.data);
                setIsLoading(false);
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return loading ? (
        <Loader />
    ) : (
        <div className="card h-xl-100 col-md-12">
            <div className="card-header position-relative py-0 border-bottom-2">
                <h3 className="mt-7">CURRENT SEMESTER CONTINIOUS ASSESSMENTS</h3>
            </div>
            <div className="card-body">
                <div className="tab-content mb-2">
                    <div className='col-ms-12'>
                        {
                            ModuleName.length > 0 ?
                            ModuleName.map((z, j) => {

                                return (

                                    <table className='table table-responsive-md' key={j}>
                                        <thead>
                                            <tr>
                                                <td colSpan={5}>
                                                    <h3 key={j}><strong>{z.ModuleCode} {z.ModuleName.toUpperCase()}</strong>
                                                    </h3>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><strong>S/NO</strong></th>
                                                <td><strong>Module Code</strong></td>
                                                <td><strong>Module Name</strong></td>
                                                <td><strong>C.A Score</strong></td>
                                                <td><strong>C.A Marked</strong></td>
                                                <td><strong>C.A Percentage</strong></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                assessment.filter(k => k.ModuleCode === z.ModuleCode).length > 0 ?
                                                    <>
                                                        {
                                                            assessment.filter(k => k.ModuleCode === z.ModuleCode).map((q, y) => {
                                                                return (

                                                                    <tr key={y}>
                                                                        <td>{y + 1}</td>
                                                                        <td>{q.ModuleCode}</td>
                                                                        <td>{q.ModuleName}</td>
                                                                        <td>{q.CAScore}</td>
                                                                        <td>{q.CAMarked}</td>
                                                                        <td>{q.CAPerCon}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <tr>
                                                            <td colSpan={6}><h3 className='col-ms-12 alert alert-danger text-center'>Sorry Your CA has not been entered for this module</h3></td>
                                                        </tr>
                                                    </>
                                            }
                                        </tbody>
                                    </table>

                                )
                            })
                            :
                            <div className="text-center alert alert-dismissible bg-danger text-white" role="alert">
                        Sorry, no assessment yet
                    </div>
                        }

                    </div>

                </div>
            </div>
        </div>
    )

}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(Assessment);
