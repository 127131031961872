import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { serverLink } from "../../../resources/url";
import Loader from "../../common/loader/loader";
import PageHeader from "../../common/pageheader/pageheader";
import { connect } from "react-redux";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Table from "../../common/table/table";

function SemesterRegistration(props) {
    const token  = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [activeSemester, setActiveSemester] = useState([])
    const [studentList, setStudentList] = useState([]);
    const [regSettings, setRegSettings] = useState([])
    const [studentSelectList, setStudentSelectList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(
        { 
            StudentID: props.loginData[0].StudentID, 
            CourseCode: props.loginData[0].StudentCourseCode, 
            StudentLevel: props.loginData[0].StudentLevel, 
            StudentSemester: props.loginData[0].StudentSemester,
            SemesterCode: ''
    })
    const [studentData, setStudentData] = useState({
        moduleList: [],
        registrationList: [],
        resultList: []
    });
    const studentID = props.loginData[0].StudentID
    let [creditLoad, setCreditLoad] = useState(0);
    const [register, setRegister] = useState({});
    const [data, setData] = useState([]);

    const [datatable, setDatatable] = useState({
       columns: [
            {
                label: "S/No",
                field: "sn",
            },
            {
                label: "Module Code",
                field: "ModuleCode",
            },
            {
                label: "Module Name",
                field: "ModuleName",
            },
            {
                label: "Course Level",
                field: "CourseLevel",
            },
            {
                label: "Course Semester",
                field: "CourseSemester",
            },
            {
                label: "Credit Load",
                field: "CreditLoad",
            },
    
            {
                label: "Module Type",
                field: "ModuleType",
            },
            {
                label: "Register",
                field: "Register",
            },
            {
                label: "Status",
                field: "Status",
            }
        ],
        rows:[]
    })
    

    const getRecords = async () => {
        let active_sem = []
        await axios.get(`${serverLink}students/registration/active/semester/setting`, token)
            .then((response) => {
                active_sem = response.data;
                setActiveSemester(response.data);
                setSelectedStudent({
                    ...selectedStudent,
                    SemesterCode: response.data[0].SemesterCode
                })
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
            
            fetchStudentRecord(active_sem)


        await axios.get(`${serverLink}students/registration/reg/setting`, token)
            .then((response) => {
                if(response.data.length > 0){
                setRegSettings(response.data);
                }
                setIsLoading(false);

            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });

    }

    const fetchStudentRecord = async (sem) => {
        const sendData = {
            StudentID: studentID,
            CourseCode: props.loginData[0].StudentCourseCode
        }
        
   
        await axios.post(`${serverLink}students/registration/student/registration/data`, sendData, token)
            .then(res => {
                const result = res.data;
                let rows = [];
                if (typeof result.running_module_list !== 'undefined') {
                    setStudentData({
                        ...studentData,
                        moduleList: result.running_module_list,
                        resultList: result.result_list,
                        registrationList: result.registered_module_list
                    })
                    if (result.running_module_list.length > 0) {
                        const student_result = result.result_list;
                        const registered_module = result.registered_module_list;
                        const running_module = result.running_module_list;
                        let credit_load = 0;
                        running_module.map((item, index) => {
                            const module_code = item.ModuleCode;

                            let status;
                            let reg_btn;

                            const filter_reg_module = registered_module.filter(i => i.ModuleCode === module_code 
                                && i.SemesterCode === sem[0].SemesterCode);
                            if (filter_reg_module.length > 0) {
                                filter_reg_module.map(item => {
                                    credit_load += running_module.filter(i => i.ModuleCode === item.ModuleCode).reduce((n, { CreditLoad }) => n + CreditLoad, 0);
                                    // setCreditLoad(running_module.filter(i => i.ModuleCode === item.ModuleCode).reduce((n, {CreditLoad}) => n + CreditLoad, 0))
                                })
                                status = <span className="badge badge-success">Registered</span>
                                reg_btn = <input type="checkbox" disabled defaultChecked={true} onChange={(e)=>{
                                    handleCheck(e, sem)
                                }} value={module_code} id="Registered" name={item.CreditLoad} />
                            } else {
                                status = <span className="badge badge-primary">Fresh</span>
                                reg_btn = <input type="checkbox" onChange={(e)=>{
                                    handleCheck(e, sem)
                                }} value={module_code} id="Fresh" name={item.CreditLoad} />
                            }

                            const filter_result = student_result.filter(i => i.ModuleCode === module_code);
                            if (filter_result.length > 0) {
                                const filter_passed_result = student_result.filter(i => i.ModuleCode === module_code && i.Decision === 'Pass');
                                if (filter_passed_result.length > 0) {
                                    status = <span className="badge badge-info">Passed</span>
                                    reg_btn = <i className="fa fa-check" />
                                } else {
                                    status = <span className="badge badge-danger">Resit</span>
                                    if (filter_reg_module.length > 0)
                                        reg_btn = <input type="checkbox" disabled defaultChecked={true} onChange={(e)=>{
                                            handleCheck(e, sem)
                                        }} value={module_code} id="Resit" name={item.CreditLoad} />
                                    else
                                        reg_btn = <input type="checkbox" onChange={(e)=>{
                                            handleCheck(e, sem)
                                        }} value={module_code} id="Resit" name={item.CreditLoad} />
                                }
                            }

                            rows.push({
                                sn: (index + 1),
                                ModuleCode: item.ModuleCode,
                                ModuleName: item.ModuleName,
                                CourseLevel: item.CourseLevel,
                                CourseSemester: item.CourseSemester,
                                CreditLoad: item.CreditLoad,
                                ModuleType: item.ModuleType,
                                Register: reg_btn,
                                Status: status
                            })
                        })
                        setCreditLoad(credit_load)
                        creditLoad = credit_load
                    } else {
                        toast.error("No running module for the selected student's course");
                    }
                } else {
                    setStudentData({
                        moduleList: [],
                        resultList: [],
                        registrationList: []
                    })
                }
                setIsLoading(false)
                setDatatable({
                    ...datatable,
                    columns: datatable.columns,
                    rows: rows,
                });
               // setData(rows)
            })
            .catch(err => {
                console.log(err)
                toast.error("Network error. Please check your connection and try again!")
            })

    }

    const handleChange = (active_sem) => {
       // console.log(active_sem)
        const filter_student = props.loginData
        if (filter_student.length > 0) {
            selectedStudent.StudentID = filter_student[0].StudentID
            selectedStudent.CourseCode = filter_student[0].StudentCourseCode
            selectedStudent.StudentLevel = filter_student[0].StudentLevel
            selectedStudent.StudentSemester = filter_student[0].StudentSemester
            selectedStudent.SemesterCode = active_sem[0].SemesterCode
            fetchStudentRecord(active_sem)
        }
    }

    const handleCheck = (e, sem) => {
        const sendData = {
            action: e.target.checked ? 'add' : 'drop',
            student_id: selectedStudent.StudentID,
            course_code: selectedStudent.CourseCode,
            semester_code: sem[0].SemesterCode,
            module_code: e.target.value,
            student_level: selectedStudent.StudentLevel,
            student_semester: selectedStudent.StudentSemester,
            status: e.target.id,
            inserted_by: props.loginData[0].StaffID
        }
        if (sendData.action === 'add') {
            const student_load = creditLoad + parseInt(e.target.name);
            if (student_load > sem[0].MaxCreditLoad) {
                toast.error(`Student's credit load (${student_load}) can't exceed the maximum allowed (${sem[0].MaxCreditLoad}).`)
                return false;
            } else {
                handleSubmit(sendData);
            }
        } else {
            handleSubmit(sendData);
        }
    }

    const handleSubmit = async (sendData) => {
        toast.info('Submitting. Please wait!')
        // setIsLoading(true);
        if (sendData.action === 'add') {
            await axios.post(`${serverLink}students/registration/register`, sendData, token)
                .then(res => {
                    const message = res.data.message;
                    if (message === 'success') {
                        toast.success('Module Added')
                        //fetchStudentRecord(selectedStudent);
                        getRecords();
                    } else if (message === 'no timetable') {
                        toast.error("No schedule for the selected module")
                        setIsLoading(false)
                    } else if (message === 'conflict') {
                        toast.error(`The selected module has a timetable conflict with ${res.data.data}`)
                        setIsLoading(false)
                    } else {
                        toast.error("Error registering module. Please try again!")
                        setIsLoading(false)
                    }
                })
                .catch(err => {
                    toast.error("Network error. Please try again.")
                })
        } else {
            await axios.patch(`${serverLink}staff/registration/drop`, sendData, token)
                .then(res => {
                    const message = res.data.message;
                    if (message === 'success') {
                        toast.success('Module Dropped')
                        getRecords();
                        //fetchStudentRecord(selectedStudent)
                    } else {
                        toast.error("Error dropping module. Please try again!")
                        setIsLoading(false)
                    }
                })
                .catch(err => {
                    toast.error("Network error. Please try again.")
                })
        }
    }

    useEffect(() => {
        getRecords();
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={`${activeSemester.length > 0 ? activeSemester[0].SemesterCode : ''} Semester Registration`}
                items={["Student Registration", "Registration", "Add & Drop"]} />
            <div className="row">
                {
                    regSettings.length === 0 ?
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Registration Period Expired</h4>
                        <p>No active registration for the current semester. Please contact your department</p>
                    </div>
                    :
                    <>
                    {activeSemester.length > 0 ? (
                    <>
                        <div className="register">
                            {
                                selectedStudent.StudentID !== '' &&
                                <>
                                    <h3>
                                        <span className="">CREDIT LOAD REGISTERED: {creditLoad}</span>
                                        <span className="float-end">MAXIMUM ALLOWED: {activeSemester[0].MaxCreditLoad}</span>
                                    </h3>
                                </>
                            }
                            {
                                datatable.rows.length > 0 && <Table data={datatable} />
                            }
                        </div>

                    </>
                ) : (
                    <div className="alert alert-info">
                        Sorry, there is no semester registration settings.{" "}
                    </div>
                )}
                    </>
                }
                
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SemesterRegistration);
