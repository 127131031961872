import React from "react";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";

export default function ModuleEvaluationForm(props) {
    return (
        <>
            <form>
                {
                    props.formType === "attendance" &&
                    <div className="col-md-12">
                        <h4 className="text-center"> <strong>CLASS ATTENDANCE BY STUDENT</strong></h4>
                        <div className="form-group">
                            <div className="text-center">
                                <label htmlFor="q1" className="col-md-12 alert alert-info"><span
                                    className="text-danger">*</span>Q1. Attendance of className</label>
                            </div>

                            <div className="form-group col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q1" type="radio" name="Q1" value={"5"} />&nbsp;Very Regular
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q1" type="radio" name="Q1" value={"4"} />Regular&nbsp;
                                            </div>
                                        </label>                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q1" type="radio" name="Q1" value={"3"} />&nbsp;Sometimes
                                            </div>
                                        </label>                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q1" type="radio" name="Q1" value={"2"} />&nbsp; Rarely
                                            </div>
                                        </label>                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q1" type="radio" name="Q1" value={"1"} />&nbsp;Not Sure
                                            </div>
                                        </label>                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q1" type="radio" name="Q1" value={"0"} />&nbsp;Do not know
                                            </div>
                                        </label>                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="form-group">
                            <div className="text-center">
                                <label htmlFor="Q2" className="alert alert-info col-md-12"><span
                                    className="text-danger">*</span>Q2. Punctuality</label></div>
                            <div className="form-group col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q2" type="radio" name="Q2" value={"5"} />&nbsp;Very Regular
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q2" type="radio" name="Q2" value={"4"} />&nbsp; Regular
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q2" type="radio" name="Q2" value={"3"} />&nbsp; Sometimes
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q2" type="radio" name="Q2" value={"2"} />&nbsp;Rarely
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q2" type="radio" name="Q2" value={"1"} />&nbsp;Not Sure
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q2" type="radio" name="Q2" value={"5"} />&nbsp;Do not know
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <h4 className="text-center"><strong>CLASS ATTENDANCE BY LECTURER</strong></h4>
                        <div className="form-group">
                            <div className="text-center">
                                <label htmlFor="q1" className="alert alert-info col-md-12"><span
                                    className="text-danger">*</span>Q1. Attendance of className</label>
                            </div>
                            <div className="form-group col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q3" type="radio" name="Q3" value={"5"} />&nbsp;Very Regualr
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q3" type="radio" name="Q3" value={"4"} />&nbsp; Regular
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q3" type="radio" name="Q3" value={"3"} />&nbsp;Sometimes
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q3" type="radio" name="Q3" value={"2"} />&nbsp;Rarely
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q3" type="radio" name="Q3" value={"1"} />&nbsp;Not Sure
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q3" type="radio" name="Q3" value={"0"} />&nbsp;Do not know
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="form-group">
                            <label htmlFor="Q4" className="alert alert-info col-md-12"><span
                                className="text-danger">*</span>Q2. Punctuality</label>
                            <div className="form-group col-md-12">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q4" type="radio" name="Q4" value={"5"} />&nbsp;Very Regular
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q4" type="radio" name="Q4" value={"4"} />&nbsp; Regular
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q4" type="radio" name="Q4" value={"3"} />&nbsp; Sometimes
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q4" type="radio" name="Q4" value={"2"} />&nbsp;Rarely
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q4" type="radio" name="Q4" value={"1"} />&nbsp;Not sure
                                            </div>
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <label className="fw-bolder fs-6">
                                            <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                <input className="form-check-input" onChange={props.onEdit} id="Q4" type="radio" name="Q4" value={"0"} />&nbsp;Do not know
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="form-group">
                            <label htmlFor="q5" className="alert alert-info col-md-12">Q3. You may provide any
                                additional information on attendance</label>
                            <div className="form-group">
                                <label className="fs-6">Additional Information</label>
                                <textarea className="form-control" cols={'10'} rows={'5'} id="Q5" name="Q5" type="text"
                                    placeholder="Additional Information"></textarea>
                            </div>
                        </div>
                        <p></p>
                        <div className="col-md-4 offset-8">
                            <button type="button" 
                            onClick={() => {
                                if (props.formType === "attendance") {
                                    if (props.data.Q1 === "" || props.data.Q2 === "" || props.Q3 === "" || props.Q4 === "") {
                                        toast.error("All questions with * sign are required in the Section 1")
                                        return false;
                                    } else {
                                        props.setFormType("delivery")
                                        props.setdisabledForms({
                                            ...props.disabledForms,
                                            delivery: '!-active',
                                            attendance: '!-inactive'
                                        })
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }

                                }
                            }} 
                            
                            id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                <span className="indicator-label">Save & Continue</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                </span>
                            </button>
                        </div>
                    </div>
                }
                {
                    props.formType === "delivery" &&
                    <>
                        <div className="col-md-12">
                            <h4 className="text-center"><strong>LECTURER'S MODE OF DELIVERY</strong></h4>
                            <hr />
                            <p className="text-center" style={{ fontSize: "16px" }}>Course Outline</p>
                            <hr />
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q1" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q1. Did the Lecturer
                                        provide you with course outline?</label>
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q6" type="radio" name="Q6" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q6" type="radio" name="Q6" value={"5"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q1" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q2. Were the topics covered
                                        before the end of the semester?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q7" type="radio" name="Q7" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q7" type="radio" name="Q7" value={"0"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <h4 className="text-center"><strong>CLASS ATTENDANCE BY LECTURER</strong></h4>
                            <hr />
                            <p className="text-center" style={{ fontSize: "16px" }}>Aims and Objectives</p>
                            <hr />
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q1" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q1. Were the aims and
                                        objectives of the course explained to you?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q8" type="radio" name="Q8" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q8" type="radio" name="Q8" value={"1"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q9" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q2. Were the topics
                                        presented htmlFor the course relevant to the stated course
                                        objectives?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q9" type="radio" name="Q9" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q9" type="radio" name="Q9" value={"0"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>

                            <div className="col-md-4 offset-8">
                                <button type="button" onClick={() => {
                                    if (props.formType === "delivery") {
                                        if (props.data.Q6 === "" || props.data.Q7 === "" || props.Q8 === "" || props.Q9 === "") {
                                            toast.error("All questions with * sign are required in the Section 2")
                                            return false;
                                        }else{
                                        props.setFormType("presentation")
                                        props.setdisabledForms({
                                            ...props.disabledForms,
                                            presentation: '!-active',
                                            delivery: '!-inactive'
                                        })
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }
                                }
                                }} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                    <span className="indicator-label">Save & Continue</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    props.formType === "presentation" &&
                    <>
                        <div className="col-md-12">
                            <h4 className="text-center"><strong>LECTURE PRESENTATION</strong></h4><p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q10" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q1. Q1.
                                        Were Lecture materials
                                        supplied to you by the Lecturer to cover the course?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q10" type="radio" name="Q10" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q10" type="radio" name="Q10" value={"0"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <label htmlFor="Q11" className="alert alert-info col-md-12"><span
                                    className="text-danger">*</span>Q2. Did you understand the
                                    lecturer's explanation of the topics covered?</label>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q11" type="radio" name="Q11" value={"5"} />&nbsp;Excellent
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q11" type="radio" name="Q11" value={"4"} />&nbsp;Very Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q11" type="radio" name="Q11" value={"3"} />&nbsp;Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q11" type="radio" name="Q11" value={"2"} />&nbsp;Fair
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q11" type="radio" name="Q11" value={"1"} />&nbsp;Poor
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <label htmlFor="Q12" className="alert alert-info col-md-12"><span
                                    className="text-danger">*</span>Q3. Did you understand the
                                    course as taught?</label>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q12" type="radio" name="Q12" value={"5"} />&nbsp;Excellent
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q12" type="radio" name="Q12" value={"4"} />&nbsp;Very Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q12" type="radio" name="Q12" value={"3"} />&nbsp;Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q12" type="radio" name="Q12" value={"2"} />&nbsp;Fair
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q12" type="radio" name="Q12" value={"1"} />&nbsp;Poor
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <label htmlFor="Q13" className="alert alert-info col-md-12"><span
                                    className="text-danger">*</span>Q4. Did you gain adequate
                                    knowledge and understanding from the course based on the
                                    lecturers explanation?</label>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q13" type="radio" name="Q13" value={"5"} />&nbsp;Excellent
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q13" type="radio" name="Q13" value={"4"} />&nbsp;Very Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q13" type="radio" name="Q13" value={"3"} />&nbsp;Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q13" type="radio" name="Q13" value={"2"} />&nbsp;Fair
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q13" type="radio" name="Q13" value={"1"} />&nbsp;Poor
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="col-md-4 offset-8">
                                <button type="button" onClick={() => {
                                    if (props.formType === "presentation") {
                                        if (props.data.Q10 === "" || props.data.Q11 === "" || props.data.Q12 === "" || props.data.Q13 === "") {
                                            toast.error("All questions with * sign are required in the Section 3")
                                            return false;
                                        }else{
                                        props.setFormType("evaluation")
                                        props.setdisabledForms({
                                            ...props.disabledForms,
                                            evaluation: '!-active',
                                            presentation: '!-inactive'

                                        })
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }
                                }
                                }} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                    <span className="indicator-label">Save & Continue</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    props.formType === "evaluation" &&
                    <>
                        <div className="col-md-12">
                            <h4 className="text-center"><strong>LECTURER'S MODE OF EVALUATION OF STUDENTS</strong></h4>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q14" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q1.
                                        Did the Lecturer ask
                                        students questions in className?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q14" type="radio" name="Q14" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q14" type="radio" name="Q14" value={"4"} />&nbsp; Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q14" type="radio" name="Q14" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q14" type="radio" name="Q14" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q14" type="radio" name="Q14" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q14" type="radio" name="Q14" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q15" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q2.  Did you feel
                                        encouraged to participate in className?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q15" type="radio" name="Q15" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q15" type="radio" name="Q15" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q15" type="radio" name="Q15" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q15" type="radio" name="Q15" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q15" type="radio" name="Q15" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q15" type="radio" name="Q15" value={"1"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q16" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q3. Do you feel motivated
                                        by offering the course?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q16" type="radio" name="Q16" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q16" type="radio" name="Q16" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q16" type="radio" name="Q16" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q16" type="radio" name="Q16" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q16" type="radio" name="Q16" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <p></p>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q16" type="radio" name="Q16" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q17" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q4.  Were tutorials and
                                        other activities given as support?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q17" type="radio" name="Q17" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q17" type="radio" name="Q17" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q17" type="radio" name="Q17" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q17" type="radio" name="Q17" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q17" type="radio" name="Q17" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q17" type="radio" name="Q17" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q18" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q5. Did the tutorials
                                        given assist you understand the course?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q18" type="radio" name="Q18" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q18" type="radio" name="Q18" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q18" type="radio" name="Q18" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q18" type="radio" name="Q18" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q18" type="radio" name="Q18" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q18" type="radio" name="Q18" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q19" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q6. Were assignments
                                        given?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q19" type="radio" name="Q19" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q19" type="radio" name="Q19" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q19" type="radio" name="Q19" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q19" type="radio" name="Q19" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q19" type="radio" name="Q19" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q19" type="radio" name="Q19" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q20" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q7. Were there Group
                                        work/Practical activities in and out the className?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q20" type="radio" name="Q20" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q20" type="radio" name="Q20" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q20" type="radio" name="Q20" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q20" type="radio" name="Q20" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q20" type="radio" name="Q20" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q20" type="radio" name="Q20" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q21" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q8. Were Assessment
                                        Test(s) given?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q21" type="radio" name="Q21" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q21" type="radio" name="Q21" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q21" type="radio" name="Q21" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q21" type="radio" name="Q21" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q21" type="radio" name="Q21" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q21" type="radio" name="Q21" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q22" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q9. How many
                                        times?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q22" type="radio" name="Q22" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q22" type="radio" name="Q22" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q22" type="radio" name="Q22" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q22" type="radio" name="Q22" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q22" type="radio" name="Q22" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q22" type="radio" name="Q22" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q23" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q10. Did the Lecturer give
                                        students feedback on their CAs?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q23" type="radio" name="Q23" value={"5"} />&nbsp;Very Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q23" type="radio" name="Q23" value={"4"} />&nbsp;Regular
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q23" type="radio" name="Q23" value={"3"} />&nbsp;Sometimes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q23" type="radio" name="Q23" value={"2"} />&nbsp;Rarely
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q23" type="radio" name="Q23" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q23" type="radio" name="Q23" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q24" className="alert alert-info col-md-12">Q11. Provide additional
                                        information on the nature of assessments done by the Lecturer,
                                        if any.</label></div>
                                <div className="form-group">
                                    <textarea className="form-control" rows={'5'} cols={'4'} id="Q24" onChange={props.onEdit} name="Q24" type="text"
                                        placeholder="Additional Information"></textarea>
                                </div>
                            </div>
                            <p></p>
                            <div className="col-md-4 offset-8">
                                <button type="button" onClick={() => {
                                    if (props.formType === "evaluation") {
                                        if (props.data.Q14 === "" || props.data.Q15 === "" || props.data.Q16 === "" || props.data.Q17 === "" || props.data.Q18 === "" || props.data.Q19 === "" || props.data.Q20 === "" || props.data.Q21 === "" || props.data.Q22 === "" || props.data.Q23 === "") {
                                            toast.error("All questions with * sign are required in the Section 4")
                                            return false;
                                        }else{
                                        props.setFormType("interpersonal")
                                        props.setdisabledForms({
                                            ...props.disabledForms,
                                            interpersonal: '!-active',
                                            evaluation: '!-inactive'
                                        })
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }
                                }
                                }} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                    <span className="indicator-label">Save & Continue</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    props.formType === 'interpersonal' &&
                    <>
                        <div className="col-md-12">
                            <h4 className="text-center"><strong>COMMUNICATION AND INTERPERSONAL SKILLS</strong></h4>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q25" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q1. What was the
                                        Lecturer's relationship with students (rapport)</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q25" type="radio" name="Q25" value={"5"} />&nbsp;Excellent
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q25" type="radio" name="Q25" value={"4"} />&nbsp;Very Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q25" type="radio" name="Q25" value={"3"} />&nbsp;Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q25" type="radio" name="Q25" value={"2"} />&nbsp;Fair
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q25" type="radio" name="Q25" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q25" type="radio" name="Q25" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q26" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q2. What was the
                                        Lecturer's treatment of students</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q26" type="radio" name="Q26" value={"5"} />&nbsp;Excellent
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q26" type="radio" name="Q26" value={"4"} />&nbsp;Very good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q26" type="radio" name="Q26" value={"3"} />&nbsp;Good
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q26" type="radio" name="Q26" value={"2"} />&nbsp;Fair
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-2">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q26" type="radio" name="Q26" value={"1"} />&nbsp;Not sure
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-3">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q26" type="radio" name="Q26" value={"0"} />&nbsp;Do not know
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q27" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q3.  Have you ever felt
                                        disregarded by the Lecturer</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q27" type="radio" name="Q27" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q27" type="radio" name="Q27" value={"0"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q28" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q4. Was there any use of
                                        inappropriate or abusive language by your Lecturer?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q28" type="radio" name="Q28" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q28" type="radio" name="Q28" value={"0"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q29" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q5. Have you ever been
                                        abused by the Lecturer?</label></div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q29" type="radio" name="Q29" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q29" type="radio" name="Q29" value={"0"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="q30" className="alert alert-info col-md-12"><span
                                        className="text-danger">*</span>Q6. Did the Lecturer dress
                                        smartly and decently?</label>
                                </div>
                                <div className="form-group col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q30" type="radio" name="Q30" value={"1"} />&nbsp;Yes
                                                </div>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="fw-bolder fs-6">
                                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                                    <input className="form-check-input" onChange={props.onEdit} id="Q30" type="radio" name="Q30" value={"1"} />&nbsp;No
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q31" className="alert alert-info col-md-12">Q7. lease provide any
                                        additional information, if any, on teacher's communication and
                                        interpersonal skills</label></div>
                                <div className="form-group">
                                    <textarea className="form-control" rows={5} cols={4} id="Q31" onChange={props.onEdit} name="Q31" type="text"
                                        placeholder="Additional Information"></textarea>
                                </div>
                            </div>
                            <p></p>
                            <div className="col-md-4 offset-8">
                                <button type="button" onClick={() => {
                                    if (props.formType === "interpersonal") {
                                        if (props.data.Q25 === "" || props.data.Q26 === "" || props.data.Q27 === "" || props.data.Q28 === "" || props.data.Q29 === "" || props.data.Q30 === "") {
                                            toast.error("All questions with * sign are required in the Section 5")
                                            return false;
                                        }else{
                                        props.setFormType("suggestion")
                                        props.setdisabledForms({
                                            ...props.disabledForms,
                                            suggestion: '!-active',
                                            evaluation: '!-inactive',                                        })
                                        window.scrollTo({ top: 0, behavior: 'smooth' });
                                    }
                                }
                                }} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                    <span className="indicator-label">Save & Continue</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                </button>

                            </div>
                        </div>
                    </>
                }
                {
                    props.formType === 'suggestion' &&
                    <>
                        <div className="col-md-12">
                            <h4 className="text-center"><strong>SUGGESTIONS FOR IMPROVING MODULE DELIVERY</strong></h4>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q32" className="alert alert-info col-md-12">Q1. Briefly state problems
                                        or challenges encountered, if any</label></div>
                                <div className="form-group">
                                    <textarea className="form-control" cols={4} rows={5} onChange={props.onEdit} id="Q32" name="Q32" type="text"
                                        placeholder="Additional Information"></textarea>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q33" className="alert alert-info col-md-12">Q2. State what needs to be
                                        done in order to address/ improve the module delivery by the
                                        lecturer.</label></div>
                                <div className="form-group">
                                    <textarea className="form-control" rows={5} cols={4} onChange={props.onEdit} id="Q33" name="Q33" type="text"
                                        placeholder="Additional Information"></textarea>
                                </div>
                            </div>
                            <p></p>
                            <div className="form-group">
                                <div className="text-center">
                                    <label htmlFor="Q34" className="alert alert-info col-md-12">Q3. General Comments, if
                                        any</label></div>
                                <div className="form-group">
                                    <textarea className="form-control" rows={5} cols={4} onChange={props.onEdit} id="Q34" name="Q34" type="text"
                                        placeholder="Additional Information"></textarea>
                                </div>
                            </div>
                            <p></p>
                            
                            <div className="col-md-12">
                                <button type="button" onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                                    <span className="indicator-label">Submit Evaluation Form</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </>
                }
            </form>
        </>
    )
}
