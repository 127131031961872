import React from "react";
import { projectNameOnTranscript } from "../../../resources/constants";
import SimpleFileUpload from 'react-simple-file-upload';
import { projectshortCode, simpleFileUploadAPIKey } from '../../../resources/constants'
export default function TranscriptForm(props) {
    return (
        <>

            <div className="form-group mb-4">
                <label htmlFor="ApplicationType">Application Type</label>
                <select
                    type={"text"}
                    id="ApplicationType"
                    name="ApplicationType"
                    className="form-select"
                    placeholder="Select your Destination Type"
                    onChange={props.onEdit}
                    value={props.data.ApplicationType}
                >
                    <option value="">Select Destination Type</option>
                    <option value="Electronic copy">Electronic copy (via email)</option>
                    <option value="Interim Transcript">Interim Transcript</option>
                    <option value="International">International (Outside Nigeria)</option>
                    <option value="To PG school">To {projectNameOnTranscript} PG school</option>
                    <option value="Within Nigeria">Within Nigeria</option>
                </select>

            </div>
            <div className="form-group mb-4">
                <label htmlFor="InstitutionName">Institution Name</label>
                <input
                    type={"text"}
                    id="InstitutionName"
                    name="InstitutionName"
                    className="form-control"
                    placeholder="Enter the Institution Name"
                    onChange={props.onEdit}
                    value={props.data.InstitutionName}
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="InstitutionEmail">Institution Email</label>
                <input
                    type={"text"}
                    id="InstitutionEmail"
                    name="InstitutionEmail"
                    className="form-control"
                    placeholder="Enter the Institution Email"
                    onChange={props.onEdit}
                    value={props.data.InstitutionEmail}
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="InstitutionPhoneNo">Institution PhoneNo</label>
                <input
                    type={"number"}
                    id="InstitutionPhoneNo"
                    name="InstitutionPhoneNo"
                    className="form-control"
                    placeholder="Enter the Institution PhoneNo"
                    onChange={props.onEdit}
                    value={props.data.InstitutionPhoneNo}
                />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="InstitutionAddress">Institution Address</label>
                <input
                    type={"text"}
                    id="InstitutionAddress"
                    name="InstitutionAddress"
                    className="form-control"
                    placeholder="Enter the InstitutionAddress"
                    onChange={props.onEdit}
                    value={props.data.InstitutionAddress}
                />
            </div>

            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="">Upload Evidence of Payment (PDF File)</span>
                </label>
                {/* <SimpleFileUpload
                    apiKey={simpleFileUploadAPIKey}
                    tag={`${projectshortCode}-transcript-evidence`}
                    onSuccess={props.handleReceiptFile}
                    preview="false"
                    width="100%"
                    height="100"
                /> */}
                <input type="file" id="ReceiptFile" className="form-control" onChange={props.onFileChange} />
            </div>

            <div className="form-group pt-2">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                </button>

            </div>

        </>
    )
}