import React from "react";
import swal from "sweetalert";

export function showAlert(title, msg, type, btnName = "OK") {
  // type: error, success, warning, confirm
  return swal({
    title: title,
    text: msg,
    icon: type,
    button: btnName,
  });
}


export function  showConfirm(title, msg, type, action)  {
  // type: error, success, warning, confirm
  return swal({
    title: title,
    text: msg,
    icon: type,
    buttons: true,
    dangerMode: true,
  })
}

export function  showContentAlert(title)  {
  // type: error, success, warning, confirm
  return swal({
    text: title,
    label: "Requested By: (Staff ID)",
    content: "input",
    button: {
      Submit: "Submit",
      cancel: "Close",
      closeModal: false,
    },
  })
}

