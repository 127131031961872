import React from "react";

export default function RegisteredModuleForm(props) {
    return (
        <>
         <div className="col-md-12">
            <div className="row">
                <div className="col-md-8">
                    <div className="form-group mt-5">
                    <select
                    name="SemesterCode"
                    id="SemesterCode"
                    className="form-select"
                    onChange={props.onEdit}
                    value={props.data.SemesterCode}
                    >
                    <option value="">Select School Semester</option>
                    {
                        
                        props.moduleSemesterList.length > 0 && props.moduleSemesterList.map((moduleSemester, index) => {
                            return <option key={index} value={moduleSemester.TT_CODE}>Semester {moduleSemester.TT_CODE}</option>
                        })
                    }
                    </select>
                    </div>
                </div>
                <div className="form-group col-md-4 pt-5">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                    </span>
                </button>
            </div>
            </div>
        </div>
        
        
            
           
             
        </>
    )
}