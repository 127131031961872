import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import Loader from '../common/loader/loader';
import Table from '../common/table/table';
import { connect } from 'react-redux';
import Modal from "../common/modal/modal";
import { showAlert } from "../common/sweetalert/sweetalert";
import { toast } from 'react-toastify';

const DigitalTransformation = (props) => {
  const token  = props.loginData[0].token;
    const [loading, setIsLoading] = useState(false)
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "Module Code",
                field: "ModuleCode",
            },
            {
                label: "Module Description",
                field: "ModuleName",
            },
            {
                label: "Is Google?",
                field: "IsGoogleClassroom",
            },
            {
                label: "Enrollment Key ",
                field: "EnrollmentKey",
            },
            {
                label: "View URL",
                field: "MeetingUrl",
            },
            // {
            //     label: "View Rport",
            //     field: "action",
            // },

        ],
        rows: [],
    });

    const studentID = props.loginData[0].StudentID;

    const [createReport, setCreateReport] = useState({});
    const [WeeklyReport, setWeeklyReport] = useState([])


    const getData = async () => {
        axios.post(`${serverLink}students/thesis/digital/transformation/report`, { studentID: studentID }, token).then((result) => {
            if (result.data.length > 0) {
                let rows = [];
                result.data.map((item, index) => {
                    rows.push({
                        sn: index + 1,
                        ModuleCode: item.ModuleCode,
                        ModuleName: item.ModuleName,
                        EnrollmentKey: item.EnrollmentKey,
                        IsGoogleClassroom: item.IsGoogleClassroom,
                        MeetingUrl: item.MeetingUrl !== '' ? <a href={item.MeetingUrl} target={"_blank"} className="btn btn-success btn-sm">Enter Class Room</a> : 'No URL',
                        EntryID: item.EntryID,
                        action: (
                            <button
                                className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_general"
                                onClick={() =>
                                    setCreateReport({
                                        EntryID: item.EntryID,
                                        ModuleCode: item.ModuleCode,
                                        ModuleName: item.ModuleName,
                                        EnrollmentKey: item.EnrollmentKey,
                                        IsGoogleClassroom: item.IsGoogleClassroom,
                                        MeetingUrl: item.MeetingUrl !== '' ? <a href={item.MeetingUrl} target={"_blank"} className="btn btn-primary btn-sm">Enter Class Room</a> : 'No URL',
                                    })
                                }
                            >
                                <i className="fa fa-pen" />
                            </button>
                        ),
                    });

                });
                setDatatable({
                    ...datatable,
                    columns: datatable.columns,
                    rows: rows,
                });
                setIsLoading(false);
            }else{
                toast.error("Sorry, no digital transformation report for your modules yet!")
            }
        })
    }

    const getWeeklyReport = async() => {
        axios.post(`${serverLink}students/thesis/digital/transformation/weekly/report`, { studentID: studentID }, token).then((result) =>{
            if(result.data.length > 0) {
                setWeeklyReport(result.data)
            }
        })
    }

    useEffect(() => {
        getData();
        getWeeklyReport();
    }, [])

    return loading ? (
        <Loader />
    ) : (
        <div className="card h-xl-100 col-md-12">
            <div className="card-header position-relative py-0 border-bottom-2">
                <h3 className="mt-7">DIGITAL TRANSFORMATION REPORT</h3>
            </div>
            <div className="card-body">
                <div className="tab-content mb-2">
                    <div className="table-responsive">
                        <Table data={datatable} />

                    </div>
                </div>
                <Modal title={"DIGITAL TRANSFORMATION WEEKLY REPORT"}>
                    
                    {
                        WeeklyReport.filter(x=>x.EntryID === createReport.EntryID).MeetingUrl
                    }
                </Modal>
            </div>
            {/*end: Card Body*/}
        </div>
    )

}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(DigitalTransformation);
