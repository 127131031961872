import React, { useEffect, useState } from "react";
import PageHeader from "../common/pageheader/pageheader";
import Modal from "../common/modal/modal";
import Table from "../common/table/table";
import Loader from "../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import PaymentRollOverForm from "./payment-roll-over-form";
import { currencyConverter, formatDateAndTime } from "../../resources/constants";
import { Link } from "react-router-dom"

function PaymentRollOver(props) {
    const token  = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [rollOverList, setRollOverList] = useState([]);

    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "Payment ID",
                field: "PaymentID",
            },
            {
                label: "Amount Paid",
                field: "AmountPaid",
            },
            {
                label: "Payment Method",
                field: "PaymentMethod",
            },
            {
                label: "Inserted Date",
                field: "InsertedDate",
            },
            {
                label: "Request",
                field: "action",
            },
        ],
        rowss: [],
    });


    const [createPayment, setCreatePayment] = useState({
        studentID: props.loginData[0].StudentID,
        SemesterCode: "",
        PaymentID: "",
        AmountPaid: "",
        PaymentMethod: "",
        Reason: "",

    });

    const studentID = props.loginData[0].StudentID

    const getData = async () => {
        axios.post(`${serverLink}students/progression/fetch/rollover/history`, { studentID: studentID }, token).then((result) => {
            if (result.data.length > 0) {
                setRollOverList(result.data);
            }

        })
        setIsLoading(false)
    }

    const getPaymentHistory = async () => {
        await axios
            .post(`${serverLink}students/progression/fetch/payment/history`, { studentID: studentID }, token)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((payment, index) => {
                        rows.push({
                            sn: index + 1,
                            PaymentID: payment.PaymentID ?? "N/A",
                            SemesterCode: payment.SemesterCode ?? "N/A",
                            AmountPaid: currencyConverter(payment.AmountPaid) ?? 'N/A',
                            PaymentMethod: payment.PaymentMethod ?? "N/A",
                            InsertedDate: formatDateAndTime(payment.InsertedDate, "date") ?? "N/A",
                            action: (
                                <button
                                    className="btn btn-sm btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_general"
                                    onClick={() =>
                                        setCreatePayment({
                                            ...createPayment,
                                            PaymentID: payment.PaymentID,
                                            SemesterCode: payment.SemesterCode,
                                            AmountPaid: payment.AmountPaid,
                                            PaymentMethod: payment.PaymentMethod,
                                            InsertedDate: payment.InsertedDate,
                                        })
                                    }
                                >
                                    <i className="fa fa-pen" />

                                </button>
                            ),
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = (e) => {
        setCreatePayment({
            ...createPayment,
            [e.target.id]: e.target.value,
        });
    };


    const onSubmit = async () => {

        if (createPayment.Reason.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter reason for rollover", "error");
            return false;
        }
        if (createPayment.Reason.length < 10) {
            showAlert("REASON TOO SHORT", "Your reason must contain a minimum of 10 characters", "error");
            return false;
        }

        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/progression/insert/payment/rollover`, createPayment, token)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Payment Rollover Submitted Successfully");
                    setIsFormLoading('off')
                    getData()
                    document.getElementById("closeModal").click()
                    setCreatePayment({
                        ...createPayment,
                        studentID: "",
                        SemesterCode: "",
                        PaymentID: "",
                        AmountPaid: "",
                        PaymentMethod: "",
                        Reason: "",
                    });
                } else if (result.data.message === "exist") {
                    showAlert(
                        "ERROR",
                        "Sorry, you have requested for payment rollover with this payment!",
                        "error"
                    );
                    document.getElementById("closeModal").click()
                    setCreatePayment({
                        ...createPayment,
                        studentID: "",
                        SemesterCode: "",
                        PaymentID: "",
                        AmountPaid: "",
                        PaymentMethod: "",
                        Reason: "",
                    });
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    };


    useEffect(() => {
        getData();
        getPaymentHistory()
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <>
            <div className="d-flex flex-column flex-row-fluid">
                <PageHeader
                    title={"Payment History"}
                    items={["Payment ", "Payment History"]}
                />
                <div className="flex-column-fluid">
                    <div className="card">
                        <div className="card-header border-0 pt-6">
                            <div className="card-title" />
                            <div className="card-toolbar">
                                <div
                                    className="d-flex justify-content-end"
                                    data-kt-customer-table-toolbar="base"
                                >
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <Table data={datatable} />
                        </div>
                    </div>
                    <Modal title={"PAYMENT ROLLOVER FORM"}>
                        <PaymentRollOverForm
                            data={createPayment}
                            onSubmit={onSubmit}
                            isFormLoading={isFormLoading}
                            onEdit={onEdit}
                        />
                    </Modal>
                </div>
            </div>
            <div className="d-flex flex-column flex-row-fluid">
                <PageHeader
                    title={"Payment RollOver History"}
                    items={["Payment ", "Payment RollOver History"]}
                />
                <div className="flex-column-fluid">
                    <div className="card">
                        <div className="card-header border-0 pt-6">
                            <div className="card-title" />
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="col-md-12" style={{ overflow: "auto" }}>
                                <table className="table table-responsive-md">
                                    <thead>
                                        <tr>
                                            <td><strong>S/No</strong></td>
                                            <td><strong>Payment ID</strong></td>
                                            <td><strong>Amount Rollover</strong></td>
                                            <td><strong>Requested Semester</strong></td>
                                            <td><strong>Date Requested</strong></td>
                                            <td><strong>Status</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rollOverList.length > 0 ?
                                                <>
                                                    {
                                                        rollOverList.map((x, y) => {
                                                            return (

                                                                <tr key={y}>
                                                                    <td>{y + 1}</td>
                                                                    <td>{x.PaymentID}</td>
                                                                    <td>{currencyConverter(x.AmountPaid)}</td>
                                                                    <td>{x.RequestedSemester}</td>
                                                                    <td>{formatDateAndTime(x.RequestedDate, "date")}</td>
                                                                    <td>{x.Status}</td>
                                                                </tr>


                                                            )

                                                        })
                                                    }
                                                </> :
                                                <></>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(PaymentRollOver);
