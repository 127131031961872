import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import { toast } from 'react-toastify';
import { decryptData } from "../../resources/constants";
import { connect } from 'react-redux';

const Accept = (props) => {
    const token  = props.loginData[0].token;
    const _id = window.location.href.split('/')[6];
    const entry_id = _id;
    const EntryIDD= decryptData(entry_id);
    const EntryID = EntryIDD.split(',')[0]
    const getData = async () => {
        axios.patch(`${serverLink}students/change-of-course/accept/change/of/course`,{EntryID:EntryID}, token).then((result) => {
            if (result.data.message === "success") {
                toast.success("Your approval is successful");
                setTimeout(() => {
                    window.close();
                }, 2000); 
            }else{
                toast.error("Sorry, your approval failed, Please try again later")
            }
        })
       
    }

    useEffect(() => {
        getData();
    }, [])

}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
      };
  };
  
  export default connect(mapStateToProps, null)(Accept);