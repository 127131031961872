import { toTitleCase } from "../../../resources/constants";

const DashboardStudentInfo = (props) => {
    return (
        <>
            <div className="h-xl-100 me-2">
                {/* <div className="rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start pb-12" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(${serverLink}public/uploads/application/document/${data.Passport})`, backgroundSize: ' 390px' }}> */}
                <div className="rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start pb-12" style={{ backgroundColor: '#080655', backgroundImage: 'url("assets/media/svg/shapes/wave-bg-purple.svg")'}}>
                    <div className="container container-fluid">
                        <div className="row col-md-12">
                            <div className="col-md-9">
                                <div className="justify-content-start" style={{ zIndex: '1' }}>
                                    <h3 className="align-items-start text-white pt-5">
                                        <div className="fw-bold fs-1 mb-3 opacity-75">{props.studentInformation.studentName}</div>
                                    </h3>
                                    <div className="fs-4 text-white" style={{ marginBottom: '0px' }}>
                                        <p style={{ fontSize: '16px', lineHeight: '1.8' }}>
                                            <strong>{props.studentInformation.studentID}</strong><br />
                                            <strong>{toTitleCase(props.studentInformation.studentCourse)}</strong> <br />
                                            <strong>{props.studentInformation.studentLevel} Level</strong><br />
                                            <strong>{toTitleCase(props.studentInformation.studentSemester)} Semester</strong><br />
                                            <strong> {toTitleCase(props.studentInformation.studentStatus)}</strong>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-3">
                            <div className="justify-content-end align-items-end">
                                <div className="symbol symbol-100px mt-4">
                                    <img src={`${serverLink}public/uploads/application/document/${data.Passport}`} alt="image"  />
                                </div>
                            </div>

                        </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DashboardStudentInfo;