import React from "react";
import JoditEditor from "jodit-react";

export default function DeferSemesterForm(props) {
    return (
        <>

            <div className="form-group mb-4">
                <label htmlFor="Semester">Number Of Semester Deferred (Min 1, Max 4)</label>
                <input
                type={'number'}
                    placeholder="No of Semester"
                    id="Semester"
                    max={4}
                    value={props.data.Semester}
                    className="form-control"
                    onChange={props.onEdit}
                />
            </div>

            <div className="form-group mb-4">
                <label htmlFor="Return Semester">Return Semester</label>
                <input
                    placeholder="Return Semester"
                    className="form-control"
                    disabled
                    value={props.data.ReturnSemester}
                />
            </div>

            <div className="form-group mb-4 col-md-12">
                <label htmlFor="Reason">Reason for Deferment Here</label>
                <JoditEditor
                id="Reason"
                name="Reason"
                value={props.data.Reason}
                tabIndex={1}
                onChange={props.onReasonChange}
            />
            </div>
            <div className="form-group pt-2">
                {
                    props.deferStatus === 'success' ?
                    <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                </button>
                :<p className="text-center alert alert-danger">You have already submitted a deferment form this semester. You would not be able to submit another one</p>
                }
                
            </div>
        </>
    )
}