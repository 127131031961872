import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { Link } from "react-router-dom"
import { encryptData } from "../../../resources/constants";
import { toast } from "react-toastify";


function ModuleEvaluation(props) {
    const token  = props.loginData[0].token;
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [isLoading, setIsLoading] = useState(true);
    const [moduleSemesterList, setModuleSemesterList] = useState([]);
    const [total, setTotal] = useState(0)
    const [selectedModule, setSelectedMdule] = useState({
        ModuleCode: "",
        ModuleName: ""
    })
    const [formType, setFormType] = useState('attendance')

    const studentID = props.loginData[0].StudentID

    const getData = async () => {
        axios.post(`${serverLink}students/registration/module/evaluation`, { studentID: studentID }, token).then((result) => {
            if (result.data.length > 0) {
                setModuleSemesterList(result.data)
                setTotal(result.data.map(e => parseInt(e.CreditLoad)).reduce((a, b) => a + b, 0))
            } else {
                toast.error ("Sorry you don't have any pre-registered module for last semester!")
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Modules Evaluation"}
                items={["Student Registration", "Registration", "Modules Evaluation"]}
            />

            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" >
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="col-md-12" style={{ overflow: "auto" }}>
                            <table className="table table-responsive-md">
                                <thead>
                                    <tr>
                                        <td><strong>S/NO</strong></td>
                                        <td><strong>Module Code</strong></td>
                                        <td><strong>Module Description</strong></td>
                                        <td><strong>Credit Load</strong></td>
                                        <td><strong>School Semester</strong></td>
                                        <td><strong>Rate</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        moduleSemesterList.length > 0 ?
                                            <>
                                                {
                                                    moduleSemesterList.map((x, y) => {
                                                        return (

                                                            <tr key={y}>
                                                                <td>{y + 1}</td>
                                                                <td>{x.ModuleCode}</td>
                                                                <td>{x.ModuleName}</td>
                                                                <td>{x.CreditLoad}</td>
                                                                <td>{x.SemesterCode}</td>
                                                                <td>{
                                                                    x.IsLecturerRated === 0 ?
                                                                        <Link className="btn btn-sm btn-primary" to={`/registration/staff-ratings/${encryptData(x.EntryID + "-" + x.SemesterCode + "-" + x.ModuleCode)}`}>
                                                                            Rate</Link> : '--'
                                                                }</td>

                                                            </tr>



                                                        )
                                                    })
                                                }
                                            </>
                                            : <></>

                                    }
                                    <tr>
                                        <td>-</td>
                                        <td>-</td>

                                        <td><strong>Total Credit Registered</strong></td>
                                        <td><strong>
                                            {
                                                total
                                            }</strong>
                                        </td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ModuleEvaluation);
