import React, { useEffect, useState } from "react";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { connect } from "react-redux";
import 'animate.css';
import { Link } from "react-router-dom"
import "../hostel/hostel.css"

function ReserveRoom(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [managerName, setManagerName] = useState([])
    const studentID = props.loginData[0].StudentID;
    const studentName = props.loginData[0].StudentName;
    const bed_number = props.hostelData.BedNo;
    const room_number = props.hostelData.RoomNo;
    const manager_phone = props.hostelData.ManagerPhone;
    const manager_email = props.hostelData.ManagerEmail;
    const hostel_for = props.hostelData.HostelFor;
    const floor_name = props.hostelData.FloorName;
    const manager_id = props.hostelData.ManagerID;

    const getData = async () => {
        axios.post(`${serverLink}students/hostel/get/manager/name/${manager_id}`).then((result) => {
            if (result.data.length > 0) {
                setManagerName(result.data.map(x => x.ManagerName))
            }
        })

        setIsLoading(false)

    }

    const printNow = () => {
        window.print();
    }

    useEffect(() => {
        getData();
    }, [])


    return isLoading ? (
        <Loader />
    ) : (
        <>
            <div className="col-md-12 myDivToPrint page">
                <div className="card">
                    <div className="card card-custom card-stretch">
                        <div class="card-body p-0 rounded px-5 py-15 d-flex align-items-center justify-content-center bg-success">
                            <h3 className="text-white"><b>Hostel Reservation Details</b></h3>
                        </div>
                    </div>
                    <div className="card-body">
                        <div class="col-xxl-7 pl-xxl-11">
                            <h2 class="font-weight-bolder text-dark mb-7" style={{ fontSize: "32px" }}>{hostel_for} Hostel</h2>
                            <div class="font-size-h2 mb-7 text-dark-50">
                                <span class="text-font-weight-boldest ml-2" style={{ color: "#ccc", fontSize: "20px", fontWeight: "bold" }}>{floor_name} Floor</span></div>
                        </div>
                        <div className="row mb-6">
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">Student Name</span>
                                    <span className="text-muted font-weight-bolder font-size-lg">{studentName}</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">Student ID</span>
                                    <span className="text-muted font-weight-bolder font-size-lg">{studentID}</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">hostel Name</span>
                                    <span className="text-muted font-weight-bolder font-size-lg">{hostel_for}  Hostel </span>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">Room Number</span>
                                    <span className="text-muted font-weight-bolder font-size-lg">{room_number}</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">Bed Number</span>
                                    <span className="text-muted font-weight-bolder font-size-lg">{bed_number}</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">Hostel Manager</span>
                                    <span className="text-muted font-weight-bolder font-size-lg">{managerName}</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">Hostel Manager Phone</span>
                                    <span className="text-muted font-weight-bolder font-size-lg">{manager_phone}</span>
                                </div>
                            </div>
                            <div className="col-6 col-md-4">
                                <div className="mb-8 d-flex flex-column">
                                    <span className="text-dark font-weight-bold mb-4">Hostel Manager Email</span>
                                    <span className="text-muted font-weight-bolder font-size-sm">{manager_email}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    <button id="printPageButton" onClick={printNow} className="btn btn-secondary col-md-12">Print <i className="bi-printer"/></button>

                    </div>
                    <div className="col-md-6">
                    <Link to="/student" id="printPageButton" className="btn btn-light-primary w-100"> Back to Dashboard
                        </Link>
                    </div>
                </div>
            </div>

        </>

    )


}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        hostelData: state.HostelDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(ReserveRoom);