import { projectCode } from "../resources/constants";

export const LoginDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_LOGIN_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const PersonalInfoDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_PERSONAL_INFO_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const PermissionDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_PERMISSION_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const FacultyListReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}FACULTY_LIST`:
      return action.payload;
    default:
      return state;
  }
};

export const DepartmentListReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}DEPARTMENT_LIST`:
      return action.payload;
    default:
      return state;
  }
};

export const currentSemesterReducer = (state='', action)=>{
  switch(action.type){
    case `SET_${projectCode}_CURRENT_SEMESTER`:
      return action.payload;
      default:
        return state;
  }

}

export const GeneralReducer = (state='', action)=>{
  switch(action.type){
    case `SET_${projectCode}_GENERAL`:
      return action.payload;
      default:
        return state;
  }

}

export const HostelDetailsReducer = (state='', action)=>{
  switch(action.type){
    case `SET_${projectCode}_HostelDetails`:
      return action.payload;
      default:
        return state;
  }

}