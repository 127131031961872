import React from "react";
import SimpleFileUpload from 'react-simple-file-upload';
import { projectshortCode, simpleFileUploadAPIKey } from '../../resources/constants'
import Select from "react-select";
export default function ThesisForm(props) {
    return (
        <>

            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="required">Thesis Title</span>
                </label>
                <input
                    type="text"
                    id="Title"
                    name="Title"
                    onChange={props.onEdit}
                    className={"form-control"}
                    value={props.data.Title}
                    placeholder={"Enter Thesis Title"}
                />
            </div>
            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="required">Select First Supervisor</span>
                </label>
                <Select
                    name="Supervisor1"
                    id="Supervisor1"
                    value={props.data.Super1}
                    onChange={props.onStaffChange1}
                    options={props.staffList}
                    placeholder="Select Supervisor"

                />
                {/* <select
                    name="Supervisor1"
                    id="Supervisor1"
                    className={"form-control"}
                    onChange={props.onEdit}
                >
                    <option value="">Select First Supervisor...</option>
                    {
                        props.staffList.length > 0 && props.staffList.map((staff, index) => {
                            return <option key={index} value={staff.StaffID}>{staff.FirstName} {staff.MiddleName} {staff.Surname}</option>
                        })
                    }
                </select> */}
            </div>
            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="required">Select Second Supervisor</span>
                </label>

                <Select
                    name="Supervisor2"
                    id="Supervisor2"
                    value={props.data.Super2}
                    onChange={props.onStaffChange2}
                    options={props.staffList}
                    placeholder="Select Supervisor"

                />
                {/* <select
                    name="Supervisor2"
                    id="Supervisor2"
                    className={"form-control"}
                    onChange={props.onEdit}
                >
                    <option value="">Select Second Supervisor...</option>
                    {
                        props.staffList.length > 0 && props.staffList.map((staff, index) => {
                            return <option key={index} value={staff.StaffID}>{staff.FirstName} {staff.MiddleName} {staff.Surname}</option>
                        })
                    }
                </select> */}
            </div>
            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="required">Select Third Supervisor</span>
                </label>

                <Select
                    name="Supervisor3"
                    id="Supervisor3"
                    value={props.data.Super3}
                    onChange={props.onStaffChange3}
                    options={props.staffList}
                    placeholder="Select Supervisor"

                />
                {/* <select
                    name="Supervisor3"
                    id="Supervisor3"
                    className={"form-control"}
                    onChange={props.onEdit}
                >   <option value="">Select Third Supervisor...</option>
                    {
                        props.staffList.length > 0 && props.staffList.map((staff, index) => {
                            return <option key={index} value={staff.StaffID}>{staff.FirstName} {staff.MiddleName} {staff.Surname}</option>
                        })
                    }
                </select> */}
            </div>
            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="required">Upload Thesis (PDF File)</span>
                </label>
                {/* <SimpleFileUpload
                    apiKey={simpleFileUploadAPIKey}
                    tag={`${projectshortCode}-student-thesis`}
                    onSuccess={props.handleUploadThesis}
                    preview="false"
                    width="100%"
                    height="100"
                /> */}
                 <input type="file" id="UploadThesis" className="form-control" onChange={props.onChange} />
            </div>


            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="required">Upload Plagiarism (PDF File)</span>
                </label>
                {/* <SimpleFileUpload
                    apiKey={simpleFileUploadAPIKey}
                    tag={`${projectshortCode}-student-thesis`}
                    onSuccess={props.handleUploadPlagiarism}
                    preview="false"
                    width="100%"
                    height="100"
                /> */}
                 <input type="file" id="UploadPlagiarism" className="form-control" onChange={props.onChange} />
            </div>

            <div className="d-flex flex-column mb-7 fv-row">
                <label className="fs-6 fw-bold mb-2">
                    <span className="required">Enter External Examiner's Name</span>
                </label>
                <input
                    type="text"
                    id={"ExternalExaminer"}
                    name="ExternalExaminer"
                    onChange={props.onEdit}
                    value={props.data.ExternalExaminer}
                    className={"form-control"}
                    placeholder={"Enter External Examiner's Name"}
                />
            </div>
            <div className="form-group pt-2">
                <button onClick={props.onSubmit} id="kt_modal_new_address_submit" data-kt-indicator={props.isFormLoading} className="btn btn-primary w-100">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                </button>
            </div>

        </>
    )
}