import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import Loader from '../common/loader/loader';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { showAlert } from "../common/sweetalert/sweetalert";

const MarkMyAttendance = (props) => {
  const token  = props.loginData[0].token;
    const [loading, setIsLoading] = useState(false)
    const studentID = props.loginData[0].StudentID;

    const getData = async (val) => {
        axios.post(`${serverLink}students/thesis/fetch/student/attendance/card/generator`, {card: val}, token).then((result) => {
            let rows =[]
            if (result.data.length > 0) {
                rows = result.data;
                axios.post(`${serverLink}students/thesis/mark/student/attendance/card/generator`, {studentID: studentID, rows: rows[0]}, token).then((result) => {
                    if(result.data.message === "success"){
                        toast.success("Your attendance was marked successfully");
                    }
                })
            }else{
                showAlert(
                    "ERROR",
                    "This code has already been used OR you have entered an invalid code. please try again!",
                    "error"
                );
            }
            setIsLoading(false);
        })
     
    }

    const onEdit = (e) => {
        const d = e.target.value
        if (d.length === 12) {
            toast.info("Please wait system loading...");
            getData(e.target.value)
        }
        
        
    };

    return loading ? (
        <Loader />
    ) : (
        <div className="card h-xl-100 col-md-12">
            <div className="card-header position-relative py-0 border-bottom-2">
                <h3 className="mt-7">MARK MY ATTENDANCE</h3>
            </div>
            <div className="card-body">
                <div className="tab-content mb-2">
                    <label htmlFor="card">Enter your Generated Code Here</label>
                <div className="form-group mt-5">
                    <input
                    type={"text"}
                    name="card"
                    id="card"
                    className="form-control"
                    placeholder='please enter your attendance card details'
                    onChange={onEdit}
                    />
                    </div>
                </div>
                
            </div>
        </div>
    )

}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(MarkMyAttendance);
