import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react"
import { serverLink } from "../../resources/url";
import Loader from '../common/loader/loader';
import Table from '../common/table/table';
import { connect } from 'react-redux';
import { formatDateAndTime, TimeTablePeriods } from '../../resources/constants';

const Attendance = (props) => {
  const token  = props.loginData[0].token;
    const [loading, setIsLoading] = useState(false)
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "Module Code",
                field: "ModuleCode",
            },
            {
                label: "Module Description",
                field: "ModuleDescription",
            },
            {
                label: "Class Attended/Expected Attendance",
                field: "Attended",
            },
            {
                label: "Attendance Percentage",
                field: "AttendancePercentage",
            },

        ],
        rows: [],
    });

    const studentID = props.loginData[0].StudentID;

    const getData = async () => {
        axios.post(`${serverLink}students/thesis/student/attendance`, { studentID: studentID }, token).then((result) => {
            if (result.data.length > 0) {
                let rows = [];
                result.data.map((item, index) => {
                    rows.push({
                        sn: index + 1,
                        ModuleCode: item.ModuleCode,
                        ModuleDescription: item.ModuleDescription,
                        Attended: item.Attended + "/10",
                        AttendancePercentage: Math.round(((item.Attended/10)*100),0)+"%",
                    });
                });
                setDatatable({
                    ...datatable,
                    columns: datatable.columns,
                    rows: rows,
                });
                setIsLoading(false);
            }
        })
    }

    useEffect(() => {
        getData();
    }, [])

    return loading ? (
        <Loader />
    ) : (
        <div className="card h-xl-100 col-md-12">
            <div className="card-header position-relative py-0 border-bottom-2">
                <h3 className="mt-7">CURRENT SEMESTER ATTENDANCE</h3>
            </div>
            <div className="card-body">
                <div className="tab-content mb-2">
                        <div className="table-responsive">
                            <Table data={datatable} />

                        </div>
                </div>
            </div>
            {/*end: Card Body*/}
        </div>
    )

}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester
    };
};

export default connect(mapStateToProps, null)(Attendance);
