import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import Modal from "../../common/modal/modal";
import Table from "../../common/table/table";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import InternshipForm from "./internship-form";
import { formatDateAndTime, projectshortCode, projectEmail, projectLogo, projectAddress, projectName, projectPhone, projectSiwesCoordinator, } from "../../../resources/constants"
import { saveAs } from "file-saver";


function Internship(props) {
    const token = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [internshipData, setInternshipData] = useState([])
    const [companyData, setCompanyData] = useState([])


    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/No",
                field: "sn",
            },
            {
                label: "Company Name",
                field: "CompanyName",
            },
            {
                label: "Company Address",
                field: "CompanyAddress",
            },
            {
                label: "Company State",
                field: "CompanyState",
            },
            {
                label: "School Semester",
                field: "SemesterCode",
            },
            {
                label: "Supervisor Name",
                field: "SupervisorName",
            },

            {
                label: "Supervisor PhoneNumber",
                field: "SupervisorPhoneNumber",
            },
            {
                label: "Status",
                field: "Status",
            },
            {
                label: "Acceptance Letter",
                field: "AcceptanceDocument",
            },
            {
                label: "Request Letter",
                field: "Download",
            },
            {
                label: "InsertedOn",
                field: "InsertedOn",
            },
        ],
        rows: [],
    });

    const [createInternship, setCreateInternship] = useState({
        studentID: props.loginData[0].StudentID,
        CompanyName: "",
        CompanyAddress: "",
        CompanyState: "",
        InsertedBy: "",
    });

    const [uploadInternship, setUploadInternship] = useState({
        studentID: props.loginData[0].StudentID,
        AcceptanceDocument: "",
        SupervisorName: "",
        SupervisorPhoneNumber: ""
    });

    const studentID = props.loginData[0].StudentID

    const getData = async () => {
        axios.post(`${serverLink}students/progression/fetch/internship/status`, { studentID: studentID }, token).then((result) => {
            if (result.data.length > 0) {
                setInternshipData(result.data[0].Status);
            }

        })
    }

    const getInternship = async () => {
        await axios
            .post(`${serverLink}students/progression/fetch/internship`, { studentID: studentID }, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setCompanyData(result.data)
                    let rows = [];
                    result.data.map((internship, index) => {
                        rows.push({
                            sn: index + 1,
                            CompanyName: internship.CompanyName ?? "N/A",
                            CompanyAddress: internship.CompanyAddress ?? "N/A",
                            CompanyState: internship.CompanyState ?? "N/A",
                            SupervisorName: internship.SupervisorName ?? "N/A",
                            SupervisorPhoneNumber: internship.SupervisorPhoneNumber ?? "N/A",
                            SemesterCode: internship.SemesterCode ?? "N/A",
                            Status: internship.Status === 0 ? <p className="badge badge-primary">Applied</p> : internship.Status === 1 ? <p className="badge badge-success">Approved</p> : internship.Status === 2 ? <p className="badge badge-danger">Rejected</p> : internship.Status === 3 ? <p className="badge badge-info">Accepted</p> : "" ?? "N/A",
                            AcceptanceDocument: internship.AcceptanceDocument === null || internship.AcceptanceDocument === "" ? "---" :
                                <a href={`${serverLink}public/uploads/${projectshortCode}/student_uploads/internship_uploads/${internship.AcceptanceDocument}`} rel="noreferrer" target={"_blank"} className="btn btn-primary btn-sm">View</a> ,
                            Download: internship.Status === 0 || internship.AcceptanceDocument === null || internship.AcceptanceDocument === "" ? '--' : <button
                                onClick={() => {
                                    toast.info('please wait system processing, this might take sometime....')
                                    getInternshipLetter(internship)
                                }}
                                className="btn btn-success btn-sm">
                                Download</button>,

                            InsertedOn: formatDateAndTime(internship.InsertedOn, "date") ?? "N/A",

                            action:
                                (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#kt_modal_general"
                                        onClick={() =>
                                            setCreateInternship({
                                                CompanyName: internship.CompanyName,
                                                CompanyAddress: internship.CompanyAddress,
                                                CompanyState: internship.CompanyState,
                                                EntryID: internship.EntryID
                                            })
                                        }
                                    >
                                        <i className="fa fa-pen" />
                                    </button>
                                )
                        });
                    });

                    setDatatable({
                        ...datatable,
                        columns: datatable.columns,
                        rows: rows,
                    });
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = (e) => {
        setCreateInternship({
            ...createInternship,
            [e.target.id]: e.target.value,
        });
    };

    const onEdit2 = (e) => {
        setUploadInternship({
            ...uploadInternship,
            [e.target.id]: e.target.value,
        });
    };

    const onFileChange = (e) => {
        setUploadInternship({
            ...uploadInternship,
            [e.target.id]: e.target.files[0]
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (createInternship.CompanyName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter company name", "error");
            return false;
        }
        if (createInternship.CompanyAddress.trim() === "") {
            showAlert("EMPTY FIELD", "Please select company address", "error");
            return false;
        }
        if (createInternship.CompanyState.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter rcompany state", "error");
            return false;
        }
        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/progression/apply/internship`, createInternship, token)
            .then((result) => {
                if (result.data.message === "success") {
                    toast.success("Record Submitted Successfully await approval");
                    setIsFormLoading('off')
                    getInternship();
                    document.getElementById("closeModal").click()
                    setCreateInternship({
                        ...createInternship,
                        studentID: props.loginData[0].StudentID,
                        CompanyName: "",
                        CompanyAddress: "",
                        CompanyState: "",
                    });
                } else if (result.data.message === "exist") {
                    setIsFormLoading('off')
                    showAlert(
                        "ERROR",
                        "Sorry you have already submit your internship details please wait for confirmation and approval",
                        "error"
                    );
                } else {
                    showAlert(
                        "ERROR",
                        "Something went wrong. Please try again!",
                        "error"
                    );
                }
            })
            .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    };

    const handleAcceptanceDocument = (url) => {
        setUploadInternship({
            ...uploadInternship,
            AcceptanceDocument: url,
        });
    }

    const onSubmit2 = async (e) => {
        e.preventDefault();
        if (uploadInternship.SupervisorName.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter SupervisorName", "error");
            return false;
        }
        if (uploadInternship.SupervisorPhoneNumber.trim() === "") {
            showAlert("EMPTY FIELD", "Please enter SupervisorPhoneNumber", "error");
            return false;
        }
        if (uploadInternship.AcceptanceDocument === "") {
            showAlert("EMPTY FIELD", "Please upload Acceptance Document", "error");
            return false;
        }
        setIsFormLoading('on')
        if (uploadInternship.AcceptanceDocument !== "") {
            const formData = new FormData();
            formData.append('studentID', props.loginData[0].StudentID)
            formData.append('AcceptanceDocument', uploadInternship.AcceptanceDocument)
            formData.append('SupervisorName', uploadInternship.SupervisorName)
            formData.append('SupervisorPhoneNumber', uploadInternship.SupervisorPhoneNumber)
            axios.post(`${serverLink}students/progression/uploadfile/internship`, formData, token).then((result) => {
                if (result.data.message === "success") {
                    toast.success("Acceptance Document Uploaded Successfully");
                    setIsFormLoading('off')
                    getInternship();
                    // getInternshipLetter()
                    document.getElementById("closeModal").click()
                    setUploadInternship({
                        ...uploadInternship,
                        AcceptanceDocument: "",
                        SupervisorName: "",
                        SupervisorPhoneNumber: ""
                    });
                }


            }).catch(err => {
                console.error('ERROR', err);
            });

        } else {
            showAlert(
                "ERROR",
                "Something went wrong. Please upload file and try again!",
                "error"
            );
        }


    };

    const getInternshipLetter = async (internship) => {
        const sendData = {
            studentInfo: {
                studentID: props.loginData[0].StudentID,
                studentCourse: props.loginData[0].StudentCourse,
                studentName: props.loginData[0].StudentName,
            },
            companyData: {
                companyName: internship.CompanyName,
                companyAddress: internship.CompanyAddress,
                companyState: internship.CompanyState


            },
            school: {
                logo: projectLogo,
                name: projectName,
                address: projectAddress,
                email: projectEmail,
                phone: projectPhone,
                shortCode: projectshortCode,
                siwesCordinator: projectSiwesCoordinator
            }
        }
        await axios.post(`${serverLink}students/progression/create-internship-letter-pdf`, sendData)
            .then(async (result) => {
                if (result.data.message === "success") {
                    await axios.post(`${serverLink}students/progression/fetch-internship-pdf`, { filename: result.data.filename }, { responseType: 'blob' })
                        .then((res) => {
                            const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                            saveAs(pdfBlob, `${props.loginData[0].StudentID}.pdf`)
                        })
                }
            })
    }


    useEffect(() => {
        getInternship()
        getData()
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Internship"}
                items={["Portals", "Internship", "Apply Internship"]}
            />
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" />
                        <div className="card-toolbar">
                            <div
                                className="d-flex justify-content-end"
                                data-kt-customer-table-toolbar="base"
                            >

                                {
                                    internshipData === 1 ?
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_general"
                                        >   Upload Acceptance Letter
                                        </button>
                                        :
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#kt_modal_general"
                                        >   Apply Internship
                                        </button>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <Table data={datatable} />
                    </div>
                </div>
                <Modal title={"APPLY INTERNSHIP"}>
                    <InternshipForm
                        onSubmit={onSubmit}
                        onSubmit2={onSubmit2}
                        isFormLoading={isFormLoading}
                        onEdit={onEdit}
                        onEdit2={onEdit2}
                        onFileChange={onFileChange}
                        internshipData={internshipData}
                        handleAcceptanceDocument={handleAcceptanceDocument}
                        data={createInternship}
                        uploadInternship={uploadInternship}
                    />
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(Internship);
