import { projectCode } from "../resources/constants";

export const setLoginDetails = (p) => {
  return {
    type: `SET_${projectCode}_LOGIN_DETAILS`,
    payload: p,
  };
};

export const setPersonalInfoDetails = (p) => {
  return {
    type: `SET_${projectCode}_PERSONAL_INFO_DETAILS`,
    payload: p,
  };
};

export const setPermissionDetails = (p) => {
  return {
    type: `SET_${projectCode}_PERMISSION_DETAILS`,
    payload: p,
  };
};

export const setFacultyList = (p)=>{
  return{
    type:`SET_${projectCode}_FACULTY_LIST`,
    payload:p
  }
}

export const setDepartmentsList = (p) => {
  return {
    type: `SET_${projectCode}_DEPARTMENT_LIST`,
    payload: p
  }
}

export const setCurrentSemester = (p)=>{
  return{
    type:`SET_${projectCode}_CURRENT_SEMESTER`,
    payload:p
  }
}

export const setGeneral = (p)=>{
  return{
    type:`SET_${projectCode}_GENERAL`,
    payload:p
  }
}

export const setHostelDetails = (p)=>{
  return{
    type:`SET_${projectCode}_HostelDetails`,
    payload:p
  }
}