import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import RegisteredModuleForm from "./register-module-form";


function RegisteredModule(props) {
    const token  = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [moduleSemesterList, setModuleSemesterList] = useState([]);
    const [total, setTotal] = useState(0)

    const [createSemester, setCreateSemester] = useState({
        studentID: props.loginData[0].StudentID,
        SemesterCode: "",
    });

    const studentID = props.loginData[0].StudentID
    const [datThing, setDatThing] = useState([])

    const getData = async () => {
        axios.post(`${serverLink}students/registration/registered/module/by/semester`, { studentID: studentID }, token).then((result) => {
            setModuleSemesterList(result.data)
        })
        setIsLoading(false)
    }

    const onEdit = (e) => {
        setCreateSemester({
            ...createSemester,
            [e.target.id]: e.target.value,
        });
    };

    const getAllRegisteredModule = async () => {
        await axios
            .post(`${serverLink}students/registration/fetch/all/r/module`, { studentID: studentID }, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setDatThing(result.data)
                    setTotal(result.data.map(e=>parseInt(e.CreditLoad)).reduce((a,b)=>a+b,0))
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const getAllRegisteredModuleBySemester = async () => {
        await axios
            .post(`${serverLink}students/registration/fetch/all/rmodule`, createSemester, token)
            .then((result) => {
                if (result.data.length > 0) {
                    setDatThing(result.data)
                    setTotal(result.data.map(e=>parseInt(e.CreditLoad)).reduce((a,b)=>a+b,0))                    
                    
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const onSubmit = async () => {

        if (createSemester.SemesterCode.trim() === "") {
            showAlert("EMPTY FIELD", "Please select school semester", "error");
            return false;
        }
        setIsFormLoading('on')
        await axios
            .post(`${serverLink}students/registration/fetch/all/rmodule`, createSemester, token)
            .then((result) => {
                setIsFormLoading('off')
                if (result.data.length > 0) {
                    setIsFormLoading('off')
                    getAllRegisteredModuleBySemester();
                    setCreateSemester({
                        ...createSemester,
                        StudentID: "",
                        SemesterCode: "",
                    })
                } else {
                    showAlert("REGISTERED MODULE", "Sorry you don't have any registered module base on your selection!", "error")
                }
            })
            .catch((error) => {
                showAlert(
                    "NETWORK ERROR",
                    "Please check your connection and try again!",
                    "error"
                );
            });

    };


    useEffect(() => {
        getData();
        getAllRegisteredModule()
    }, [])

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Registered Modules"}
                items={["Student Registration", "Registration", "Registered Modules"]}
            />

            <RegisteredModuleForm data={createSemester}
                moduleSemesterList={moduleSemesterList}
                onSubmit={onSubmit}
                isFormLoading={isFormLoading}
                onEdit={onEdit}
            />

            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-header border-0 pt-6">
                        <div className="card-title" />
                        <div className="card-toolbar">

                            <div
                                className="d-flex justify-content-end"
                                data-kt-customer-table-toolbar="base"
                            >
                            </div>
                        </div>
                    </div>
                    <div className="card-body pt-0">
                        <div className="col-md-12" style={{overflow:"auto"}}>
                        <table className="table table-responsive-md">
                            <thead>
                                <tr>
                                    <td><strong>S/NO</strong></td>
                                    <td><strong>Module Code</strong></td>
                                    <td><strong>Module Description</strong></td>
                                    <td><strong>Credit Load</strong></td>
                                    <td><strong>Semester</strong></td>
                                    <td><strong>Level</strong></td>
                                    <td><strong>School Semester</strong></td>
                                    <td><strong>Status</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    datThing.length > 0 ?
                                        <>
                                            {
                                                datThing.map((x, y) => {
                                                    return (
                                                    
                                                        <tr key={y}>
                                                            <td>{y+1}</td>
                                                            <td>{x.ModuleCode}</td>
                                                            <td>{x.ModuleName}</td>
                                                            <td>{x.CreditLoad}</td>
                                                            <td>{x.StudentSemester}</td>
                                                            <td>{x.StudentLevel}</td>
                                                            <td>{x.SemesterCode}</td>
                                                            <td>{x.Status}</td>
                                                        </tr>
                                                        
                                                        
                                                        
                                                    )
                                                })
                                            }
                                        </>
                                        : <></>
                                        
                                }
                            <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    
                                    <td><strong>Total Credit Registered</strong></td>
                                    <td><strong>
                                        {
                                            total 
                                        }</strong>
                                    </td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(RegisteredModule);
