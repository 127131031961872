import React, { useEffect, useState } from "react";
import PageHeader from "../common/pageheader/pageheader";
import Modal from "../common/modal/modal";
import Table from "../common/table/table";
import Loader from "../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../resources/url";
import { showAlert } from "../common/sweetalert/sweetalert";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { EmailTemplates, sendEmail } from "../../resources/constants"
import ChangeOfCourseForm from "./change-of-course-form";
import * as DOMPurify from 'dompurify';

function ChangeOfCourse(props) {
  const token  = props.loginData[0].token;
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState('off');
  const [check, setCheck] = useState([]);
  const [courseList, setCourseList] = useState([])
  const [parentInfo, setParentInfo] = useState({});

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "Current Course",
        field: "Curr",
      },
      {
        label: "Change To Course",
        field: "Req",
      },
      {
        label: "Semester Code",
        field: "SemesterCode",
      },
      {
        label: "Guardian Name",
        field: "GuardianName",
      },
      {
        label: "Guardian Phone",
        field: "GuardianPhone",
      },
      {
        label: "Guardian EmailAddress",
        field: "GuardianEmailAddress",
      },
      {
        label: "Status",
        field: "Status",
      },
      {
        label: "Petition",
        field: "Petition",
      },
    ],
    rows: [],
  });

  const [createCourse, setCreateCourse] = useState({
    studentID: props.loginData[0].StudentID,
    studentName: props.loginData[0].StudentName,
    studentCourseCode: props.loginData[0].StudentCourseCode,
    studentCourseName: props.loginData[0].StudentCourse,
    NewCourse: "",
    ParentName: "",
    ParentEmail: "",
    ParentPhone: "",
  });

  const studentID = props.loginData[0].StudentID;
  const type = studentID.toString().toLowerCase().includes('pgs') === true ? true : false
  const email = props.loginData[0].EmailAddress
  const StudentName = props.loginData[0].StudentName


  const getData = async () => {
    axios.post(`${serverLink}students/change-of-course/fetch/all/course`, { studentID: studentID, type: type }, token)
      .then((result) => {
        setCourseList(result.data)
      })
    axios.post(`${serverLink}students/deferment/deferment/parent/info`, { studentID: studentID }, token).then((result) => {
      if (result.data.length > 0) {
        setParentInfo(result.data[0])
        setCreateCourse({
          ...createCourse,
          ParentName: result.data[0].ParentName,
          ParentEmail: result.data[0].ParentPhoneNumber,

        })
      }

    })
    axios.post(`${serverLink}students/change-of-course/check/change/of/course`, { studentID: studentID }, token).then((result) => {
      if (result.data.length > 0) {
        setCheck(result.data.message)
        let rows = [];
        result.data.map((course, index) => {
          rows.push({
            sn: index + 1,
            Curr: course.Curr ?? "N/A",
            Req: course.Req ?? 'N/A',
            SemesterCode: course.SemesterCode ?? "N/A",
            GuardianName: course.GuardianName ?? "N/A",
            GuardianPhone: course.GuardianPhone ?? "N/A",
            GuardianEmailAddress: course.GuardianEmailAddress ?? "N/A",
            Status: course.Status === 0 ? <p className="badge badge-primary">Applied</p> : course.Status === 1 ? <p className="badge badge-success">Guardian Approved</p> : course.Status === 2 ? <p className="badge badge-danger">Guardian Rejected</p> : course.Status === 3 ? <p className="badge badge-success">Admission Approved</p> : course.Status === 4 ? <p className="badge badge-danger">Admission Rejected</p> : course.Status === 5 ? <p className="badge badge-success">Registrar Approved</p>  : <p className="badge badge-danger">Registrar Rejected</p>  ?? "N/A",
            Petition: <blockquote dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(course.Petition)}}/> ?? "N/A",
            action: (
              <button
                className="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_general"
                onClick={() =>
                  setCreateCourse({
                    Curr: course.Curr,
                    Req: course.Req,
                    SemesterCode: course.SemesterCode,
                    GuardianName: course.GuardianName,
                    GuardianPhone: course.GuardianPhone,
                    GuardianEmailAddress: course.GuardianEmailAddress,
                    Status: course.Status === 0 ? <p className="badge badge-primary">Applied</p> : course.Status === 1 ? <p className="badge badge-success">Guardian Approved</p> : course.Status === 2 ? <p className="badge badge-danger">Guardian Rejected</p> : course.Status === 3 ? <p className="badge badge-success">Admission Approved</p> : course.Status === 4 ? <p className="badge badge-danger">Admission Rejected</p> : course.Status === 5 ? <p className="badge badge-success">Registrar Approved</p>  : <p className="badge badge-danger">Registrar Rejected</p>  ?? "N/A",
                    Petition: <blockquote dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(course.Petition)}}/> ,
                  })
                }
              >
                <i className="fa fa-pen" />
              </button>
            ),
          });
        });

        setDatatable({
          ...datatable,
          columns: datatable.columns,
          rows: rows,
        });
      }
    })
    setIsLoading(false)
  }
  const onEdit = (e) => {
    setCreateCourse({
      ...createCourse,
      [e.target.id]: e.target.value,
    });
  };

  const onReasonChange = (e) => {
    setCreateCourse({
        ...createCourse,
        "Reason": e
    })
}


  const onSubmit = async () => {

    if (createCourse.NewCourse.trim() === "") {
      showAlert("EMPTY FIELD", "Please select new course", "error");
      return false;
    }
    if (createCourse.ParentName.trim() === "") {
      showAlert("EMPTY FIELD", "Please Parent full name", "error");
      return false;
    }
    if (createCourse.ParentEmail.trim() === "") {
      showAlert("EMPTY FIELD", "Please enter Parent email", "error");
      return false;
    }
    if (createCourse.ParentPhone.trim() === "") {
      showAlert("EMPTY FIELD", "Please enter Parent phone no", "error");
      return false;
    }
    if (createCourse.Reason.trim() === "") {
      showAlert("EMPTY FIELD", "Please enter reason", "error");
      return false;
    }
    if (createCourse.Reason.length < 10) {
      showAlert("REASON IS TOO SHORT", "Your reason must contain a minimum of 10 characters", "error");
      return false;
    }

    setIsFormLoading('on')
    await axios
      .post(`${serverLink}students/change-of-course/submit/change/of/course`, createCourse, token)
      .then((result) => {
        if (result.data.message === "success") {
          toast.success("Change of Course Submitted Successfully");
          const maildata = {
            data: createCourse,
            EntryID: result.data.EntryID,
            RequestedCourse: result.data.RequestedCourse,
          }
          const s_mail = EmailTemplates('4', maildata)
          sendEmail(email, s_mail.subject, s_mail.title, StudentName, s_mail.body, '')
          const p_mail = EmailTemplates('5', maildata)
          sendEmail(createCourse.ParentEmail, p_mail.subject, p_mail.title, result.data.GuardianName, p_mail.body, '')
          setIsFormLoading('off')
          getData();
          setCreateCourse({
            ...createCourse,
            StudentID: "",
            NewCourse: "",
            studentCourseCode:"",
            Reason:"",
          });
          document.getElementById("closeModal").click()
        } else if(result.data.message === 'exist'){
          showAlert("SORRY","Sorry there is an existing change of course request that has not been resolved","error")
          setIsFormLoading('off')
          setCreateCourse({
            ...createCourse,
            StudentID: "",
            NewCourse: "",
            studentCourseCode:"",
            Reason:"",
          });
          document.getElementById("closeModal").click()
        }else {
          showAlert(
            "ERROR",
            "Something went wrong. Please try again!",
            "error"
          );
        }
      })
      .catch((error) => {
        showAlert(
          "NETWORK ERROR",
          "Please check your connection and try again!",
          "error"
        );
      });

  };


  useEffect(() => {
    getData();
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <div className="d-flex flex-column flex-row-fluid">
      <PageHeader
        title={"Change of Course"}
        items={["Student Account", "Change of Course"]}
      />
      <div className="flex-column-fluid">
        <div className="card">
          <div className="card-header border-0 pt-6">
            <div className="card-title" />
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                {
                  check=== 'exist' ?
                    <button
                    disabled
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_general"
                    >
                      Change of Course
                    </button>
                    :
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_general"
                    >
                      Change of Course
                    </button>
                }

              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <Table data={datatable} />
          </div>
        </div>
        <Modal title={"CHANGE OF COURSE FORM"}>
          <ChangeOfCourseForm
            courseList={courseList}
            data={createCourse}
            onSubmit={onSubmit}
            isFormLoading={isFormLoading}
            onEdit={onEdit}
            onReasonChange={onReasonChange}
          />
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loginData: state.LoginDetails,
  };
};

export default connect(mapStateToProps, null)(ChangeOfCourse);
